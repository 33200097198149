import fetch from "isomorphic-fetch"
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"

// Define your primary API endpoint
const primaryEndpoint = "https://api.daliaempower.com/graphql"

// Define a secondary API endpoint
const secondaryEndpoint = "https://apix.daliaempower.com/graphql"

// Create a function that determines which endpoint to use for a request
function determineEndpoint(operation) {
  // You can use any logic here to decide which endpoint to use.
  const useSecondaryEndpoint = operation.getContext().useSecondaryEndpoint

  return useSecondaryEndpoint ? secondaryEndpoint : primaryEndpoint
}

const customHttpLink = new ApolloLink((operation, forward) => {
  const httpLink = new HttpLink({ uri: determineEndpoint(operation) })
  return httpLink.request(operation, forward)
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("dalia.auth.login")

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  })

  return forward(operation)
})

// const refreshTokenMiddleware = new ApolloLink((operation, forward) => {
//   return forward(operation).map(response => {
//     // Check if the response contains an error related to token expiration
//     const errors = response.errors
//     if (errors) {
//       for (const error of errors) {
//         if (error.extensions && error.extensions.code === "UNAUTHENTICATED") {
//           // The server responded with an UNAUTHENTICATED error, indicating token expiration
//           // Implement your token refreshing logic here

//           // Retrieve the current refresh token from localStorage
//           const refreshToken = localStorage.getItem("refreshToken")

//           // Define the RefreshAppUserToken mutation
//           const refreshMutation = gql`
//             mutation RefreshAppUserToken($refreshToken: String!) {
//               refreshAppUserToken(refreshToken: $refreshToken) {
//                 success
//                 token
//                 refreshToken
//               }
//             }
//           `

//           // Execute the mutation to refresh the token
//           return client
//             .mutate({
//               mutation: refreshMutation,
//               variables: { refreshToken },
//             })
//             .then(mutationResponse => {
//               const data = mutationResponse.data.refreshAppUserToken
//               if (data.success) {
//                 // Token refresh was successful, update localStorage with the new access token
//                 localStorage.setItem("dalia.auth.login", data.token)

//                 // Re-send the original operation with the new token
//                 const originalOperation = operation
//                 originalOperation.setContext({
//                   headers: {
//                     Authorization: `Bearer ${data.token}`,
//                   },
//                 })

//                 return originalOperation
//               } else {
//                 // Handle token refresh failure as needed
//                 console.error("Token refresh failed:", data)
//                 throw new Error("Token refresh failed")
//               }
//             })
//             .catch(error => {
//               console.error("Token refresh error:", error)
//               throw error
//             })
//         }
//       }
//     }

//     return response
//   })
// })

export const client = new ApolloClient({
  fetch: fetch,
  cache: new InMemoryCache(),
  link: from([authMiddleware, customHttpLink]),
})
